import { Component } from 'react';
import './App.css';
import { withAuth0 } from '@auth0/auth0-react';

import { uuid } from 'uuidv4';

import {
	Box,
	Button,
	Heading,
	Grommet,
	Text,
	Grid,
	Main,
	Paragraph,
	Spinner,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
    List,
    Form,
    FormField,
    TextInput
} from 'grommet';
import { Magic, UserAdmin, Group, Storage } from 'grommet-icons';
import { render } from '@testing-library/react';

import { clone } from 'lodash';

export interface Project {
    name: string;
    id: string;
}

interface State {
    projects: Project[],
    newProjectValue: string;
}

interface Props {
	auth0: any;
    accessToken: string;
}

class Dashboard extends Component<Props, State> {
	state: State = {
        projects: [],
        newProjectValue: ''
	}

    handleAddNewProject = () => {
        const newProject = {name: this.state.newProjectValue, id: uuid()}

        let newProjects = clone(this.state.projects);

        newProjects.push(newProject);

        this.setState({
            projects: newProjects,
            newProjectValue: ''
        });
    }

    handleSelectProject = (projectID: string) => {

    }

    render() {
        const {
            projects,
            newProjectValue
        } = this.state;

        return <span>
            <Heading level="3" size="medium" margin={{ "top": "medium" }}>
                Your Account ({this.props.auth0.user.nickname})
            </Heading>
            <Box align="center" justify="center" direction="column" flex={false} width="large" border={{ "side": "all", "color": "dark-6", "size": "small", "style": "solid" }} pad={{ "top": "medium", "bottom": "medium", "horizontal": "xlarge" }} background={{ "color": "light-1" }} round="xsmall">
                <Text truncate={false}>
                    <strong>First Time Here?</strong> Start by creating a project for your game.  Our wizard will guide you through the process of getting each service you want to use up and running.
                </Text>
            </Box>
            <Heading level="2" size="medium">
                Projects
            </Heading>

            <Form 
                value={{ name: newProjectValue}} 
                onChange={(nextValue: any) => {
                    this.setState({newProjectValue: nextValue.name})
                }}
                onReset={() => {this.setState({newProjectValue: ''})}}
                onSubmit={() => {this.handleAddNewProject()}}
            >
                <Box width="100%" direction="row" gap="small">
                    <FormField width="100%" name="name" htmlFor="text-input-id" label="New Project Name">
                        <TextInput id="text-input-id" name="name" />
                    </FormField>
                    <Button style={{height: '35px', top: '40px', position: 'relative'}} type="submit" primary label="Add" />
                </Box>
            </Form>

            <List
                primaryKey="name"
                secondaryKey="id"
                border="all"
                a11yTitle="List of Projects In Your Account"
                data={projects}
                action={(item, index) => {
                    return <Button label="Dashboard" primary onClick={() => {
                        this.handleSelectProject(item.id);
                    }} />
                }}
            />
            
        </span>
    }
}

export default Dashboard;
