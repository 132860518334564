import { Component } from 'react';
import './App.css';
import { withAuth0 } from '@auth0/auth0-react';

import {
	Box,
	Button,
	Heading,
	Grommet,
	Text,
	Grid,
	Main,
	Paragraph,
	Spinner,
	Card,
	CardBody,
	CardFooter,
	CardHeader
} from 'grommet';
import { Magic, UserAdmin, Group, Storage } from 'grommet-icons';
import Dashboard from './Dashboard';

const services = [
	{
		name: 'Object Storage',
		blurb: 'Simply by attaching a script you can have selected properties of your game object or scripts persist across plays and platforms.',
		icon: <Storage size="xlarge" color="brand" />,
		enabled: false
	},
	{
		name: 'Multiplayer',
		blurb: 'After a shockingly simple and well documented setup process you\'ll be able to immediately start seeing other clients connected to the same server.',
		icon: <Magic size="xlarge" color="brand" />,
		enabled: false
	},{
		name: 'Authentication',
		blurb: 'Let us take all the complexity and heavy lifting out of managing user accounts for authentication and cross-progression.  Simply fill out some branding informaiton and get a URL you can forward un-authenticated clients to.',
		icon:<UserAdmin size="xlarge" color="brand" />,
		enabled: false
	},{
		name: 'Social',
		blurb: 'As if we weren\'t doing enough for you; by simply enabling the options and adding the required UI prefabs to you\'re scene you can immediately gain access to party features, friends lists, chat and other social essentials.',
		icon: <Group size="xlarge" color="brand" />,
		enabled: false
	}
]

export const theme = {
	"name": "RealmNetTheme",
	"rounding": 4,
	"spacing": 24,
	"defaultMode": "light",
	"global": {
		"colors": {
			"brand": {
				"dark": "#899878",
				"light": "#222725"
			},
			"background": {
				"dark": "#121113",
				"light": "#F7F7F2"
			},
			"background-back": {
				"dark": "#121113",
				"light": "#F7F7F2"
			},
			"background-front": {
				"dark": "#121113",
				"light": "#F7F7F2"
			},
			"background-contrast": {
				"dark": "#121113",
				"light": "#F7F7F2"
			},
			"text": {
				"dark": "#EEEEEE",
				"light": "#121113"
			},
			"text-strong": {
				"dark": "#FFFFFF",
				"light": "#000000"
			},
			"text-weak": {
				"dark": "#CCCCCC",
				"light": "#444444"
			},
			"text-xweak": {
				"dark": "#999999",
				"light": "#666666"
			},
			"border": {
				"dark": "#444444",
				"light": "#CCCCCC"
			},
			"control": "brand",
			"active-background": "background-contrast",
			"active-text": "text-strong",
			"selected-background": "brand",
			"selected-text": "text-strong",
			"status-critical": "#FF4040",
			"status-warning": "#E4E6C3",
			"status-ok": "#00C781",
			"status-unknown": "#CCCCCC",
			"status-disabled": "#CCCCCC",
			"graph-0": "brand",
			"graph-1": "status-warning",
			"focus": {
				"light": "#E4E6C3",
				"dark": "#E4E6C3"
			}
		},
		"font": {
			"family": "pt-sans-pro, sans-serif"
		},
		"active": {
			"background": "active-background",
			"color": "active-text"
		},
		"hover": {
			"background": "active-background",
			"color": "active-text"
		},
		"selected": {
			"background": "selected-background",
			"color": "selected-text"
		}
	},
	"chart": {},
	"diagram": {
		"line": {}
	},
	"meter": {},
	"layer": {
		"background": {
			"dark": "#121113",
			"light": "#F7F7F2"
		}
	},
	"email": "justin@barnyak.com",
	"date": "2021-08-12T06:25:06.000Z"
}

const _audience = '4kp9fz9jw7.execute-api.us-east-1.amazonaws.com';
const _scope = 'read:current_user';

interface State {
	showSidebar: boolean;
	feature: string; // just use this as an enum to know what page the user is on
	user: any;
	accessToken: string;
	loadingDashboard: boolean;
	showCustomerPortal: boolean;
	loadingCustomerPortal: boolean;
	selectedCustomerPortal: string;
	customerPortalData: any;
}

interface Props {
	auth0: any;
}

class App extends Component<Props, State> {
	state: State = {
		showSidebar: true,
		feature: '',
		user: undefined,
		accessToken: '',
		loadingDashboard: true,
		showCustomerPortal: false,
		loadingCustomerPortal: true,
		selectedCustomerPortal: '',
		customerPortalData: undefined
	}

	// handleTrySilentAuthentication = (onSuccess: any, onFail: any) => {
	// 	try {
	// 		this.props.auth0.getAccessTokenSilently
	// 	}
	// 	catch(e) {

	// 	}
	// }

	makeRequest = (path: string, cb?: any) => {
		this.props.auth0.getAccessTokenWithPopup({
			audience: _audience,
			scope: _scope
		}).then((token: string) => {
			console.log(token);
			this.setState({
				accessToken: token
			});

			fetch("https://4kp9fz9jw7.execute-api.us-east-1.amazonaws.com/dev/" + path, {
				headers: {
					'Authorization': "Bearer " + token
				},
			})
				.then(res => res.json())
				.then(
					(result) => {
						if (cb) { cb(result); }
					},
					(error) => {
						console.log(error);
					}
				)
		})
	}

	handleLoadingFeature = (feature: string) => {
		if (feature === 'dashboard') {
			this.makeRequest('core?func=projects', (data: any) => {
				console.log(data);

				this.setState({
					loadingDashboard: false
				});
			});
		}
	}

	handleLoadingCustomerPortal = (customerID: string) => {
		this.setState({
			showCustomerPortal: true,
			loadingCustomerPortal: true,
			selectedCustomerPortal: customerID
		});

		// Grab the project data
		this.makeRequest("core?func=project&projectID=" + customerID, (data: any) => {
			this.setState({
				customerPortalData: data.branding,
				loadingCustomerPortal: false,
			});
		});
	}

	componentWillMount() {
		const hostname = window.location.hostname;

		if (hostname.includes(".portal.")) {
			const customerName = hostname.split(".portal.")[0];

			this.handleLoadingCustomerPortal(customerName);
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.auth0 !== this.props.auth0) {
			console.log(this.props.auth0);
			if (this.props.auth0.isAuthenticated && this.state.user === undefined) {
				this.setState({
					user: {}
				});
			}
		}

		if (prevState.feature !== this.state.feature) {
			this.handleLoadingFeature(this.state.feature);
		}
	}

	handleLoginButton = () => {
		if (this.props.auth0) {
			this.props.auth0.loginWithRedirect();
		}
	}

	handleDashboardButton = () => {
		this.setState({
			feature: 'dashboard'
		})
	}

	handleDocumentation = () => {
		this.setState({
			feature: 'documentation'
		})
	}

	handleDownloads = () => {
		this.setState({
			feature: 'downloads'
		})
	}

	handlePricing = () => {
		this.setState({
			feature: 'pricing'
		})
	}

	handleContactUs = () => {
		this.setState({
			feature: 'contact'
		})
	}

	renderLoginButton = () => {
		const {
			auth0,
		} = this.props;

		if (auth0.isLoading) {
			return <div style={{ marginTop: '4px', marginRight: '10px' }}><Spinner /></div>
		}

		if (auth0.isAuthenticated) {
			return <Button label="Dashboard" onClick={this.handleDashboardButton} primary />;
		}

		return <Button label="Login" onClick={this.handleLoginButton} primary />;
	}

	renderFeatureCard = (config: any) => {
		return <Card style={{width: '100%', margin: '10px'}}>
			<CardHeader align="center" direction="row" flex={false} justify="center" gap="medium" pad="small">
				<Heading level="3" textAlign="center">
					{config.name}
				</Heading>
			</CardHeader>
			<CardBody pad="small" direction="column" justify="center" align="center">
				{config.icon}
				<Text margin={{ "top": "medium", "left": "small", "right": "small" }}>
					{config.blurb}
				</Text>
			</CardBody>
			<CardFooter align="center" direction="column" flex={false} justify="center" gap="medium" pad="small">
				{config.enabled === true ? <Button label="More Details" /> : <Button label="Coming Soon" />}
			</CardFooter>
		</Card>
	}

	renderHomePage = () => {

		let features: JSX.Element[] = [];

		services.forEach((service: any) => {
			features.push(this.renderFeatureCard(service));
		})

		return <span>
			<Heading level="3" size="medium" textAlign="center" style={{maxWidth: 'unset'}}>
				First class Turn-Key Unity User and Networking services 
			</Heading>
			<Box align="center" justify="center" direction="column" flex={false} width="large" border={{ "side": "all", "color": "dark-6", "size": "small", "style": "solid" }} pad={{ "top": "medium", "bottom": "medium", "horizontal": "xlarge" }} background={{ "color": "light-1" }} round="xsmall">
				<Text textAlign="center" truncate={false}>
					We provide a series of essential infastructure, API's and services to run a succesfull MMORPG.  Services including* data persistence, authentication, realm servers, social and multiplayer.
				</Text>
			</Box>
			<Box align="center" justify="center" direction="column" flex={false} width="large" pad={{ "top": "small", "bottom": "medium" }}>
				<Text textAlign="center" truncate={false} margin={{ "top": "medium" }}>
					With the drop of a few scripts you can take a single-player only unity project to a full-fledge MMORPG with chat, social based authentication and near real-time multiplayer functionality with almost no configuration.
				</Text>
			</Box>
			<Paragraph>
				[GIF RECORDING HERE OF DOING A FEW OF THOSE THINGS.  3 IN A ROW ON DESKTOP, 1 AT A TIME ON MOBILE ]
			</Paragraph>
			<Box justify="center" direction="column" flex={false} width="large">
				{features}
			</Box>
			<Box align="end" justify="start" direction="row" pad="large" gap="small" height={{ "max": "small", "height": "small", "min": "xxsmall" }} flex="grow">
				<Button label="Contact Us" onClick={this.handleContactUs} size="medium" hoverIndicator />
				<Button label="Pricing" onClick={this.handlePricing} size="medium" primary hoverIndicator />
				<Button onClick={this.handleDownloads} label="Downloads" />
				<Button onClick={this.handleDocumentation} label="Documentation" />
			</Box>
		</span>

	}

	renderDashboard = () => {
		return <Dashboard accessToken={this.state.accessToken} auth0={this.props.auth0} />
	}

	remderCurrentFeature = () => {
		const {
			feature
		} = this.state;

		if (feature === 'dashboard') return this.renderDashboard();

		return <span>
			<Heading level="3" size="medium" margin={{ "top": "medium" }}>
				Coming Soon!
			</Heading>
			<Box align="center" justify="center" direction="column" flex={false} width="large" border={{ "side": "all", "color": "dark-6", "size": "small", "style": "solid" }} pad={{ "top": "medium", "bottom": "medium", "horizontal": "xlarge" }} background={{ "color": "light-1" }} round="xsmall">
				<Text textAlign="center" truncate={false}>
					Check back in soon for updates about this product or offering.
				</Text>
			</Box>
		</span>
	}

	renderCustomerPortal = () => {
		return <span>test</span>
	}

	render() {
		const {
			feature,
			showCustomerPortal
		} = this.state;

		if (showCustomerPortal) {
			return this.renderCustomerPortal();
		}

		return <Grommet full theme={theme}>
			<Box fill="vertical" overflow="hidden" align="center" flex="grow" background={{ "color": "dark-1" }} direction="row">
				<Main background={{ "color": "accent-1", "dark": false, "image": "url('https://realmnet.net/bg.png')" }}>
					<Grid width="100%" columns={["full"]} justifyContent="start" rows={["xsmall"]} pad={{ "bottom": "medium" }} alignContent="start" align="stretch" justify="stretch">
						<Box align="start" justify="end" flex="shrink" fill="horizontal" width="100%" pad={{ "left": "medium" }} background={{ "image": "url('https://realmnet.net/Triangle.svg')", repeat: 'no-repeat', position: 'left', size: 'auto' }} height="xsmall" gap="none" margin={{ "bottom": "large" }}>
							<Box style={{ height: '50px', position: 'absolute', top: '0px', left: '0px', width: '100%', backgroundColor: 'white' }}>
								<Box style={{ top: '7px', position: 'relative' }} align="stretch" justify="end" height="xxsmall" direction="row" pad={{ "right": "small", "bottom": "small" }} flex="shrink" gap="small" responsive={false}>
									{this.renderLoginButton()}
									<Button onClick={this.handleDownloads} label="Downloads" />
									<Button onClick={this.handleDocumentation} label="Documentation" />
								</Box>
							</Box>
							<Box onClick={() => {
								this.setState({
									feature: ''
								});
							}} style={{ zIndex: 10 }} align="center" justify="center" pad="small" background={{ "color": "active-text" }}>
								<Text size="4xl" textAlign="start" truncate={false}>
									RealmNet
								</Text>
							</Box>
						</Box>
						<Box style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', width: '90%' }} align="center" justify="center" background={{ "image": "url('')", "position": "top", "color": "background" }} direction="column" gap="xsmall" flex="grow" responsive wrap={false} alignSelf="stretch" pad={{ "bottom": "medium" }}>
							{(feature === '') ? this.renderHomePage() : this.remderCurrentFeature()}
						</Box>
					</Grid>
				</Main>
			</Box>
		</Grommet >
	}
}

//@ts-ignore
export default withAuth0(App);
